import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import { Button, Form, Spinner } from "react-bootstrap";
import { navigate } from "gatsby";

const Cangjo = () => {
    return (
        <Layout title="Cangjo">
            <ul className="list-group rounded-0">
                <li className="list-group-item lh-condensed d-flex justify-content-between">
                    <span>Kas bulan juni</span>
                    <span className="text-muted">Rp25,000</span>
                </li>
                <li className="list-group-item lh-condensed d-flex justify-content-between">
                    <span>Kas bulan juli</span>
                    <span className="text-muted">Rp25,000</span>
                </li>
                <li className="list-group-item lh-condensed d-flex justify-content-between">
                    <span>Kas bulan agustus</span>
                    <span className="text-muted">Rp25,000</span>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                    <span>Total</span>
                    <strong>Rp75,000</strong>
                </li>
            </ul>
        </Layout>
    );
};

export default Cangjo;
